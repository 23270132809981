import { gql, useMutation } from '@apollo/client'
import {
  Alert,
  AlertIcon,
  Box,
  chakra,
  Input,
  Textarea,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormElement, FormSection } from 'starterComponents'
import { Container } from 'starterUiComponents'

const SEND_EMAIL = gql`
  mutation ($input: SendEmailInput!) {
    sendEmail(input: $input) {
      origin
      sent
      message
    }
  }
`

const ContactPage = () => {
  const { register, handleSubmit, reset } = useForm()
  const [emailSent, setEmailSent] = useState(false)
  const [sendEmail] = useMutation(SEND_EMAIL)

  const onSubmit = (data) => {
    const { firstName, lastName, email, phone, comments, reason } = data
    const mailBody = `First Name: ${firstName},</br> Last Name: ${lastName},</br> Email: ${email}, </br>Phone: ${phone},</br> Reason for Contact: ${reason}, </br> Comments: ${comments}`
    sendEmail({
      variables: {
        input: {
          to: email,
          from: 'connect@atsg.biz',
          subject: 'We received your Email | Contact US',
          body: mailBody,
          clientMutationId: Date.now().toString(),
        },
      },
    })
    reset()
    setEmailSent(true)
  }

  return (
    <Container>
      <Box
        className="mb-9"
        sx={{ a: { fontWeight: 400, fontSize: 'sm' }, div: { fontSize: 'sm' } }}
      >
        <h4 className="mb-2 font-bold text-l">
          For billing or other questions please call:
        </h4>
        <div>
          Billing: <a href="tel:(305) 670-4161">(305) 670-4161</a>
        </div>
        <div>
          Questions: <a href="tel:(800) 245-7722">(800) 245-7722</a>
        </div>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection title="How Can We Help You" bgColor="blue">
          <FormElement
            id="first-name"
            label="First Name:"
            required
            type="input"
          >
            <Input placeHolder="First Name" name="firstName" ref={register} />
          </FormElement>
          <FormElement id="last-name" label="Last Name:" type="input">
            <Input placeHolder="Last Name" name="lastName" ref={register} />
          </FormElement>
          <FormElement id="email" label="Email:" required type="input">
            <Input
              type="email"
              placeHolder="Email"
              name="email"
              ref={register}
            />
          </FormElement>
          <FormElement id="phone" label="Phone:" required type="input">
            <Input placeHolder="Phone" name="phone" ref={register} />
          </FormElement>
          <FormElement
            id="reason"
            label="Reason for Contact:"
            name="reason"
            required
            type="input"
          >
            <Input
              placeHolder="Reason for Contact"
              name="reason"
              ref={register}
            />
          </FormElement>
          <FormElement
            id="comments"
            label="Comments:"
            sx={{ label: { mt: 3 } }}
          >
            <Textarea
              placeholder="Comments"
              name="comments"
              height="240px"
              ref={register}
            />
          </FormElement>
        </FormSection>
        <div className="flex justify-end">
          <chakra.input
            className="flex items-center justify-center pt-2 font-bold text-white uppercase transition-all duration-300 cursor-pointer bg-lightBlue rounded-xl text-md hover:bg-blue"
            h="55px"
            w="200px"
            type="submit"
            value="Submit"
          />
        </div>
        {emailSent && (
          <div className="flex justify-end ">
            <Alert status="success" mt={8} width="300px">
              <AlertIcon />
              Your Email has been sent!
            </Alert>
          </div>
        )}
      </form>
    </Container>
  )
}

export default ContactPage
